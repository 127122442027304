import MarginContainer from "../../components/layout/margin-container";
import HeadingH1, {HeadingH4} from "../../components/text/heading";
import ColoredLine from "../../components/colored-line";
import StyledText from "../../components/text/styled-text";
import List, {ListItem} from "../../components/layout/list";
import React from "react";
import styled, { css } from "styled-components";
import {animated} from "react-spring";

export const Wrapper = styled(animated.div)`
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: -webkit-fill-available;
    ${props => props.width && css`
        width: ${props.width};
    `}
`;
export const DefaultContent = props => {
    return (
        <Wrapper {...props}>
            <MarginContainer margin={"48px 48px 0 48px"}>
                <HeadingH4>Welcome to the</HeadingH4>
                <HeadingH1>RISE Reading Workshop</HeadingH1>
                <ColoredLine margin={"5px 0 0 2px"}/>
            </MarginContainer>
            <MarginContainer margin={"20px 48px 0 48px"}>
                <StyledText>
                    Learning to read, unlike learning to speak, is not a natural process. It can be challenging. But
                    that doesn’t mean it’s impossible, and it definitely doesn’t mean it can’t be fun. This
                    invaluable free tool will train you to bring the love of reading to students of all abilities.
                    You will learn:
                </StyledText>
                <MarginContainer margin={"4px 0 0 25px"}>
                    <List>
                        <ListItem>An explicit, systematic, and sequential phonics method that has been proven time
                            and again.</ListItem>
                        <ListItem>The "why" behind the decoding, spelling, and pronunciation of English
                            words.</ListItem>
                        <ListItem>How to empower students to become life-long readers, and learners.</ListItem>
                    </List>
                </MarginContainer>
                <MarginContainer margin={"4px 0 0 0"}>
                    <StyledText fontSize={"12px"}>The RISE Reading Workshop material is copyrighted and has been
                        donated by Reading Horizons.</StyledText>
                </MarginContainer>
            </MarginContainer>
        </Wrapper>
    )
};