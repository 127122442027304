import React from "react";
import styled, { css } from "styled-components";

const ColoredLine = styled.div`
    display: inline-flex;
    width: 85px;
    border: solid #FBC20F 2.5px;
    margin-top: 5px;
    ${props => props.color && css`
        border-color: ${props.color};
    `}
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
    ${props => props.width && css`
        width: ${props.width};
    `}
    ${props => props.thickness && css`
        border-width: ${props.thickness};
    `}
`;

export default ColoredLine;