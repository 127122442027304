import React, {createContext, useReducer, useState} from "react";
import InputReducer, { IS_VALID, IS_NOT_VALID } from "../reducers/input-reducer";

const InputContext = createContext();


const InputContextProvider = ({children}) => {
    const [errors, dispatch] = useReducer(InputReducer, {});
    
    const register = name => {
        let state = {...errors};
        dispatch({field: name, type: IS_NOT_VALID});
    };
    
    const update = (name, isValid) => {
        let state = {...errors};
        state[name] = isValid;
        dispatch({field: name, type: isValid ? IS_VALID : IS_NOT_VALID});
    };
    
    return (
        <InputContext.Provider value={{errors, register, update}}>
            {children}
        </InputContext.Provider>
    )
};

export { InputContext, InputContextProvider };