import { useState, useCallback } from "react";

const useDetectBrowser = () => {
    let [browser, setBrowser] = useState("unknown");
    let isChrome = navigator.userAgentData || !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    let isFireFox = typeof InstallTrigger !== 'undefined';
    let isOpera = navigator.userAgent.indexOf(' OPR/') >= 0;
    let isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    let isIE = /*@cc_on!@*/false || !!document.documentMode;
    var isEdge = !isIE && !!window.StyleMedia;
    let isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

    let set = useCallback((browserName) => setBrowser(browserName), [browser]);

    if (browser != "unknown") return browser;
    if (isFireFox)
        set("firefox");
    else if (isOpera)
        set("opera");
    else if (isSafari)
        set("safari");
    else if (isIE)
        set("ie");
    else if (isEdge)
        set("edge");
    else if (isEdgeChromium)
        set("edge-chromium");
    else if (isChrome)
        set("chrome");
    return browser;
};


export default useDetectBrowser;