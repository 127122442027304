import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
    const location = useLocation();
    return () => {
        return new URLSearchParams(location.search);
    };
};

export default useQuery;