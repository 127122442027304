import React, { useState } from "react";
import styled, { css } from "styled-components";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCaretDown);

const SelectStyleContainer = styled.div`
    border: 1px solid #CBEBFF;
    border-radius: 2px;
    background-color: white;
    max-width: 300px;
    max-height: 36px;
    line-height: 24px;
    min-width: 300px;
    ${props => props.minWidth && css`
        min-width: ${props.minWidth};
    `}
`;

const Menu = styled(components.Menu)`
    margin-top: 0 !important;
    border-radius: 0 0 2px 2px !important;
    //z-index: -1 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
`;

const SingleValue = styled(components.SingleValue)`
    font-size: 12px;
`;

const Option = styled(components.Option)`
    font-size: 12px !important;
    line-height: 18px !important;
    cursor: pointer !important;
    ${props => props.isSelected && css`
        background-color: #eff8ff !important;
        color: #555555 !important;
    `}
    ${props => props.isFocused && css`
        background-color: #eff8ff !important;
        color: #555555 !important;
    `}
`;

const Control = styled(components.Control)`
    min-height: 34px !important;
`;

const CaretDownIcon = () => {
    return <FontAwesomeIcon icon="caret-down" />;
};

const DropDownWrapper = styled(components.DropdownIndicator)`
    color: #555555 !important;
`;

const DropdownIndicator = props => {
    return (
        <DropDownWrapper {...props}>
            <CaretDownIcon/>
        </DropDownWrapper>
    );
};

const SelectContainer = ({ children, ...props }) => {
    return (
        <SelectStyleContainer>
            <components.SelectContainer {...props}>
                {children}
            </components.SelectContainer>
        </SelectStyleContainer>
    )
};
const IndicatorSeparator = ({ innerProps }) => {
    return null; //<span style={indicatorSeparatorStyle} {...innerProps} />;
};

const CustomerSelect = props => {
    return (
        <Select
            {...props}
            components={{SelectContainer, DropdownIndicator, IndicatorSeparator, SingleValue, Control, Option, Menu}}
            language={"english"} width={"300px"}/>
    )
};

export default CustomerSelect;