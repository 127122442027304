import React, { useState } from "react";
const NEXT_STATE = "nextState";
const PREVIOUS_STATE = "previousState";
const UPDATE_USER = "updateUser";




const CreateUserReducer = (state, payload) => {
    const s = {...state};
    const { data } = {...payload};
    let {field, value} = {...data};
    s[field] = value;
    return s;
};

export const UpdateCreateState = (state, action) => {
    let s = state;
    switch(action){
        case NEXT_STATE:
            s = s + 1;
            break;
        case PREVIOUS_STATE:
            s = s - 1;
            break;
    }
    return s;
};

export default CreateUserReducer;
export { NEXT_STATE, PREVIOUS_STATE, UPDATE_USER};