import React from "react";
import styled, { css } from "styled-components";

const MarginContainer = styled.div`
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
    ${props => props.padding && css`
        padding: ${props.padding};
    `}
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${props => props.direction && css`
        flex-direction: ${props.direction};
    `}
    ${props => props.align && css`
        align-items: ${props.align};
    `}
    ${props => props.justify && css`
        justify-content: ${props.justify};
    `}
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
    ${props => props.padding && css`
        padding: ${props.padding};
    `}
    ${props => props.width && css`
        width: ${props.width};
    `}
`;

export default MarginContainer;