import React from "react";
import styled, { css } from "styled-components";
import { animated } from "react-spring";

const InfoBlock = styled(animated.div)`
    background-image: url('hot air balloon.png');
    background-color: #36A2F9;
    background-position: top left;
    background-repeat: none;
    opacity: 1;
    width: 674px;
    height: 100%;
    display: inline-block;
    position: relative;
    align-items: center;
    ${props => props.width && css`
        width: ${props.width};
    `}
`;

export default InfoBlock;