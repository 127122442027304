import React from "react";
import styled, { css } from "styled-components";

const Label = styled.label`
    color: #555555;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
    
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
`;

export default Label;