import React, {createContext, useReducer} from "react";
import CreateUserReducer, {UpdateCreateState} from "../reducers/create-user-reducer";

const CreateUserContext = createContext();

const CreateUserContextProvider = ({init, children}) => {
    const [ currentState, dispatchUpdateState ] = useReducer(UpdateCreateState, 0);
    const [ user, dispatchUpdateUser ] = useReducer(CreateUserReducer, {...init});
    return (
        <CreateUserContext.Provider value={{currentState, dispatchUpdateState, user, dispatchUpdateUser}}>
            {children}
        </CreateUserContext.Provider>
    )
};

export { CreateUserContext, CreateUserContextProvider };
