import React from 'react';
import Login from "./pages/login";
import './sass/main.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";

import useQuery from "./hooks/usequery";
import SignUp from "./pages/createaccount";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {InputContextProvider} from "./contexts/inputcontextprovider";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const Workshop = () => {
    const queryParams = useQuery();
    const type = queryParams().get("type");
    const alert = queryParams().get("alert");
    const message = queryParams().get("message");
    const userId = queryParams().get("userId");
    const code = queryParams().get("code");

    return (
        <Switch>
            <Route path={"/recoverpassword"}>
                <ForgotPassword/>
            </Route>
            <Route path={"/resetpassword"}>
                <ResetPassword userId={userId} code={code}/>
            </Route>
            <Route path={"/register"}>
                <SignUp/>
            </Route>
            <Route path={"/"}>
                <Login type={type} alert={alert} messageText={message}/>
            </Route>
        </Switch>
    )
};

const App = () => {
      return (
          <Elements stripe={stripePromise}>
              <InputContextProvider>
                  <div className="App">
                      <Router>
                          <Workshop/>
                      </Router>
                  </div>
              </InputContextProvider>
          </Elements>
      );
};

export default App;
