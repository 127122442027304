import React, {Fragment, useState, useRef, useContext, useEffect} from "react";
import Panel from "../components/layout/panel";
import MarginContainer, {FlexContainer} from "../components/layout/margin-container";
import HeadingH1, {HeadingH4} from "../components/text/heading";
import ColoredLine from "../components/colored-line";
import StyledText from "../components/text/styled-text";
import List, {ListItem} from "../components/layout/list";
import InfoBlock from "../components/layout/info-block";
import { useTransition, animated, useSpring, useChain, config } from "react-spring";
import styled from "styled-components";
import Image from "../components/image";
import ActionBlock from "../components/layout/action-block";
import Notification from "../components/notification";
import Input, {InputWithIcon} from "../components/form/input";
import Label from "../components/text/label";
import StyledLink from "../components/text/link";
import Button, {ButtonLink} from "../components/button";
import useCookie from "../hooks/cookiehook";
import UnOrderedList from "../components/layout/unorderedlist";
import CustomerSelect from "../components/form/select";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import "../sass/stripe.scss";
import {InputContext, InputContextProvider} from "../contexts/inputcontextprovider";
import {CreateUserContext, CreateUserContextProvider} from "../contexts/createUserContext";
import {NEXT_STATE, PREVIOUS_STATE, UPDATE_USER} from "../reducers/create-user-reducer";
import {CARD_ELEMENT_OPTIONS} from "../constants/stripe-constants";
import {emailValidation, passwordValidation, TypeOptions} from "../constants/user-type-constants";
import {DefaultContent, Wrapper} from "./shared/content";
import ProgressBar from "../components/progress";
import {Message} from "./shared/message";

const CreateContent = props => {
    return (
            <Wrapper {...props}>
                <FlexContainer margin={"64px 0 0 0"} justify={"center"}>
                    <Image src={"/images/logowhite.png"}/>
                </FlexContainer>
                <FlexContainer margin={"21px 0 0 0"}>
                    <HeadingH4>Create an Account</HeadingH4>
                </FlexContainer>
                <FlexContainer margin={"48px 0 0 0"} align={"center"}>
                    <ProgressBar/>
                </FlexContainer>
            </Wrapper>
    )
};

const DefaultAction = props => (
    <Wrapper {...props}>
        <MarginContainer margin={"79px 92px 32px"}>
            <Image src={"/images/riselogoblue.png"}/>
        </MarginContainer>
        <FlexContainer>
            <FlexContainer align={"start"}>
                <Label>Email</Label>
                <InputWithIcon type={"email"} id={"username"} name={"Email"} icon={"envelope"}/>
            </FlexContainer>
            <FlexContainer align={"start"} margin={"10px 0 0 0"}>
                <Label>Password</Label>
                <InputWithIcon id={"password"} name={"Password"} icon={"lock"} type={"password"} />
            </FlexContainer>
            <FlexContainer direction={"row"} justify={"space-between"} margin={"30px 0 0 0"} width={"300px"}>
                <StyledLink to={"/recoverpassword"}>Forgot Password?</StyledLink>
                <Button>Login</Button>
            </FlexContainer>
            <FlexContainer direction={"row"} margin={"30px 0 0 0"}>
                <StyledText color={"black"} fontSize={"14px"}>
                    Don't have an account? <StyledLink to={"/register"}>Sign up</StyledLink>
                </StyledText>
            </FlexContainer>
        </FlexContainer>
    </Wrapper>
);

const CreateAction = ({rest}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [ stripeErrors, setStripeErrors ] = useState(null);
    const listLineHeight = "20px";
    
    const { errors } = useContext(InputContext);
    const { currentState, user, dispatchUpdateUser, dispatchUpdateState } = useContext(CreateUserContext);
    const [ message, setMessage ] = useState("");
    const [ alertType, setAlertType ] = useState("alert");
    const [ allowFinishClick, setAllowFinishClick ] = useState(false);
    const [userId, setUserId] = useState("");
    const cookie = useCookie("X-XSRF-TOKEN");

    useEffect(() => {
        setFinish();
    }, [currentState, user.type, user.ClassCode]);
    
    const onClickNext = (e, direction) => {
        //console.log(createState);
        if(!direction) return;
        if(direction === 1)
            dispatchUpdateState(NEXT_STATE);
        else
            dispatchUpdateState(PREVIOUS_STATE);
    };
    
    const onClickFinish = async e => {
      e.preventDefault();
      let formData = {};
      formData = parseUser(user);
      formData = await parseCC(formData);
      console.log(formData);
      if(formData) await sendFormData(formData);
    };
    
    const sendFormData = async formData => {
        const serialized = JSON.stringify(formData);
        try {

            const response = await fetch("/account/register", {
                method: 'POST',
                headers: {
                    "RequestVerificationToken": cookie,
                    "Content-Type": "application/json"
                },
                body: serialized
            });
            if (response.ok) {
                let u = await response.json();
                setUserId(u.id);
                dispatchUpdateState(NEXT_STATE)
            } else if (response.status === 422) {
                let data = JSON.parse(await response.json());
                setMessage(data.message);
                setAlertType("alert");
            }
        } catch (e) {
            console.log(e);
        }
    };
    
    const parseCC = async formData => {
        if(formData.institutionType !== 1)
            return formData;
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if(result.error) {
            setStripeErrors(result.error.message);
            return null;
        }else {
            setStripeErrors(null);
            formData["stripeSourceId"] = result.token.id;
            return formData;
        }
    };
    
    const parseUser = (user) => {
        let formData = {};
        for(let key in user){
            if(user.hasOwnProperty(key) && user[key] !== ""){
                if(typeof user[key] == "string") {
                    formData[`${key.substr(0,1).toLowerCase()}${key.substr(1)}`] = user[key];
                }else {
                    formData["institutionType"] = user[key].value;
                }
            }
        }
        return formData;
    };
    
    const onHandleStripeChange = e => {
      if (e.error)
          setStripeErrors(e.error.message);
      else 
          setStripeErrors(null);
    };

    const loginLinkClicked = async e => {
        window.location.href = "/";
    }
    
    const sendConfirmationEmail = async e => {
        let serialized = JSON.stringify({email: user.Email});
        let response = await fetch("/account/confirmationemail", {
            method: 'POST',
            headers: {
                "RequestVerificationToken" : cookie,
                "Content-Type" : "application/json"
            },
            body: serialized
        });
        if(response.ok) {
            setMessage("Confirmation Email sent.");
            setAlertType("success");
        }
    };
    
    
    
    const onChange = e => {
        let prevState = {...user};
        let field = "";
        let data = null;
        setMessage("");
        if(e.hasOwnProperty("currentTarget")){
            //prevState[e.currentTarget.id] = e.currentTarget.value;
            field = e.currentTarget.name;
            data = e.currentTarget.value;
        }else{
            //prevState.type = e;
            field = "type";
            data = e;
        }
        
        dispatchUpdateUser({action: UPDATE_USER, data: { field: field, value: data}});
        setFinish();
    };
    
    const setFinish = () => {
        if(currentState === 2){
            let isRestricted = (user.type.value === 0) ? !errors["ClassCode"] : false;
            setAllowFinishClick(isRestricted);
        }
    };
    
    return (
        <Wrapper {...rest}>
            {message &&
                <Message message={message} alert={alertType}/>
            }
            {currentState === 0 &&
                <Fragment>
                    <FlexContainer margin={"125px 159px 34px 119px"}>
                        <FlexContainer align={"start"} width={"277px"} margin={"0 0 0 0"}>
                            <StyledText color={"#555555"} font={"Montserrat"} weight={"700"}>What's your name?</StyledText>
                            <ColoredLine margin={"5px 0 0 2px"} width={"38px"} thickness={"1.5px"}/>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer margin={"0 20px 0 0"}>
                        <FlexContainer align={"start"}>
                            <Label>First Name</Label>
                            <Input type={"text"} id={"firstName"} name={"FirstName"} value={user.FirstName} onChange={onChange} required/>
                        </FlexContainer>
                        <FlexContainer align={"start"}>
                            <Label>Last Name</Label>
                            <Input type={"text"} id={"lastName"} name={"LastName"} value={user.LastName} onChange={onChange} required/>
                        </FlexContainer>
                        <FlexContainer align={"flex-end"} margin={"9px 0 0 0"} width={"300px"}>
                            <Button type={"button"} onClick={e => onClickNext(e, 1)} disabled={!errors["FirstName"] || !errors["LastName"]}>Next</Button>
                        </FlexContainer>
                        <FlexContainer direction={"row"} margin={"30px 0 0 0"}>
                            <StyledText color={"black"} fontSize={"14px"}>
                                Already have an account? <StyledLink to={"/"}>Log in</StyledLink>
                            </StyledText>
                        </FlexContainer>
                    </FlexContainer>
                </Fragment>
            }
            {currentState === 1 && 
                <Fragment>
                    <FlexContainer margin={"125px 159px 34px 119px"}>
                        <FlexContainer align={"start"} width={"277px"} margin={"0 0 0 0"}>
                            <StyledText color={"#555555"} font={"Montserrat"} weight={"700"}>How do you want to log in?</StyledText>
                            <ColoredLine margin={"5px 0 0 2px"} width={"38px"} thickness={"1.5px"}/>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer margin={"0 10px 0 0"}>
                        <FlexContainer align={"start"}>
                            <Label>Email</Label>
                            <Input type={"email"} id={"email"} name={"Email"} value={user.Email} onChange={onChange} validation={emailValidation} required/>
                        </FlexContainer>
                        <FlexContainer align={"start"} margin={"10px 0 0 0"}>
                            <Label>Password</Label>
                            <Input id={"password"} name={"Password"} type={"password"} value={user.Password} onChange={onChange} required validation={passwordValidation}/>
                        </FlexContainer>
                        <FlexContainer>
                            <UnOrderedList columns={2} type={{color: "#36A2F9"}} margin={"16px 0 16px 0"}>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight}>One lowercase character</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight}>One uppercase character</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight}>One number</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight} margin={"0 0 0 16px"}>One special character</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight} margin={"0 0 0 16px"}>8 characters minumum</ListItem>
                            </UnOrderedList>
                        </FlexContainer>
                        <FlexContainer direction={"row"} justify={"flex-end"}  margin={"9px 0 0 0"} width={"300px"}>
                            <Button type={"button"} bordered margin={"0 12px 0 0"} onClick={e => onClickNext(e, -1)}>Back</Button>
                            <Button type={"button"} onClick={e => onClickNext(e, 1)} disabled={!errors["Email"] && !errors["Password"]}>Next</Button>
                        </FlexContainer>
                    </FlexContainer>
                </Fragment>
            }
            {currentState === 2 && 
                <Fragment>
                    <FlexContainer margin={"106px 159px 34px 119px"}>
                        <FlexContainer align={"start"} width={"277px"} margin={"0 0 0 0"}>
                            <StyledText color={"#555555"} font={"Montserrat"} weight={"700"}>Which account type are you?</StyledText>
                            <ColoredLine margin={"5px 0 0 2px"} width={"38px"} thickness={"1.5px"}/>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer margin={"0 10px 0 0"}>
                        <FlexContainer align={"start"} width={"300px"}>
                            <Label>Type of Account</Label>
                            <CustomerSelect style={"max-width: 300px; max-height: 36px; min-width: 300px;"} 
                                            isSearchable={false} options={TypeOptions} defaultValue={TypeOptions[0]} id={"type"} name={"Type"} value={user.type}
                            onChange={onChange}/>
                        </FlexContainer>
                        {user.type.value === 0 &&
                            <FlexContainer align={"start"} margin={"16px 0 0 0"}>
                                <Label>Class Code</Label>
                                <Input type={"text"} id={"classCode"} name={"ClassCode"} value={user.ClassCode} onChange={onChange} required/>
                            </FlexContainer>
                        }
                        {user.type.value === 1 &&
                            <Fragment>
                                <FlexContainer align={"start"} margin={"16px 0 0 0"} width={"300px"}>
                                    <Label htmlFor="card-element">Sign up for your 30 day trial.</Label>
                                    <StyledText color={"#555555"} fontSize={"10px"} lineHeight={"12px"} margin={"0 0 6px 0"}>After the initial trial period your card will be charged $100 dollars for a one year subscription.</StyledText>
                                    <CardElement
                                        id="card-element"
                                        className={"stripe-container"}
                                        options={CARD_ELEMENT_OPTIONS}
                                        onChange={onHandleStripeChange}
                                    />
                                    {stripeErrors &&
                                        <StyledText color={"#FF0000"} fontSize={"10px"}>{stripeErrors}</StyledText>
                                    }
                                </FlexContainer>
                                <FlexContainer align={"start"} margin={"16px 0 0 0"}>
                                    <Label margin={"0 0 6px 0"}>Coupon Code (optional)</Label>
                                    <StyledText color={"#555555"} fontSize={"10px"} lineHeight={"12px"} margin={"0 0 6px 0"}>Enter your coupon code to receive a discount.</StyledText>
                                    <Input type={"text"} id={"couponCode"} name={"CouponCode"} value={user.CouponCode} onChange={onChange}/>
                                </FlexContainer>
                            </Fragment>
                        }
                        <FlexContainer direction={"row"} justify={"flex-end"}  margin={"32px 0 0 0"} width={"300px"}>
                            <Button type={"button"} bordered margin={"0 12px 0 0"} onClick={e => onClickNext(e, -1)}>Back</Button>
                            <Button type={"button"} onClick={onClickFinish} disabled={allowFinishClick}>Finish</Button>
                        </FlexContainer>
                    </FlexContainer>
                </Fragment>
            }
            {currentState === 3 &&
                <Fragment>
                    <FlexContainer margin={"106px 159px 34px 119px"}>
                        <FlexContainer align={"start"} width={"277px"} margin={"0 0 0 0"}>
                            <StyledText color={"#555555"} font={"Montserrat"} weight={"700"}>Confirmation</StyledText>
                            <ColoredLine margin={"5px 0 0 2px"} width={"42px"} thickness={"1.5px"}/>
                        </FlexContainer>
                </FlexContainer>
                    {user.type.value <= 1 && 
                        <FlexContainer margin={"0 10px 0 0"}>
                            <FlexContainer width={"300px"} align={"start"}>
                                <StyledText color={"#555555"} fontSize={"16px"} width={"300px"} lineHeight={"22px"}>We emailed a confirmation link to</StyledText>
                                <StyledText color={"#555555"} fontSize={"16px"} width={"300px"} width={"300px"} lineHeight={"22px"} weight={"600"}>{user.Email}</StyledText>
                                <StyledText color={"#555555"} font={"Open Sans"} fontSize={"16px"} width={"300px"} margin={"18px 0 0 0"}>Check your email for a link to sign in!</StyledText>
                            </FlexContainer>
                            <FlexContainer width={"300px"} align={"start"} margin={"64px 0 0 0"}>
                                <StyledText color={"#555555"} fontSize={"14px"} width={"300px"} lineHeight={"22px"}>Didn't get a confirmation email?</StyledText>
                        <StyledText color={"#555555"} fontSize={"14px"} width={"300px"} lineHeight={"22px"}>Check your spam folder or <StyledLink onClick={sendConfirmationEmail} to={""}>send again</StyledLink>!</StyledText>
                            </FlexContainer>
                        </FlexContainer>
                }
                {user.type.value > 1 &&
                    <FlexContainer margin={"0 10px 0 0"}>
                        <FlexContainer width={"300px"} align={"start"}>
                            <StyledText color={"#555555"} fontSize={"16px"} width={"300px"} lineHeight={"22px"}>Your account has been created!</StyledText>
                            <StyledText color={"#555555"} fontSize={"16px"} width={"300px"} width={"300px"} lineHeight={"22px"} weight={"600"}>{user.Email}</StyledText>
                        <StyledText color={"#555555"} font={"Open Sans"} fontSize={"16px"} width={"300px"} margin={"18px 0 0 0"}>Please follow this link to <StyledLink to={"/"} onClick={loginLinkClicked}>Log in</StyledLink> and complete registration.</StyledText>
                        </FlexContainer>
                    </FlexContainer>
                }
                </Fragment>
        }
        </Wrapper>
    )
};

const SignUp = () => {
    
    const infoPanelProps = useSpring({
        from: { width: 674 },
        to: { width: 555 },
        config: config.slow
    });
    const defaultProps = useSpring({
        from: { opacity: 1},
        to: {opacity: 0}
    });
    const createProps = useSpring({
        from: { opacity: 0},
        to: {opacity: 1},
        config: config.slow
    });
    return (
        <CreateUserContextProvider init={{FirstName: "", LastName: "", Email: "", Password: "", CouponCode: "", type: TypeOptions[0]}}>
            <Panel>
                <InfoBlock style={infoPanelProps}>
                    <DefaultContent style={defaultProps}/>
                    <CreateContent style={createProps}/>
                </InfoBlock>
                <ActionBlock>
                    <DefaultAction style={defaultProps}/>
                    <CreateAction style={createProps} width={"100%"}/>
                </ActionBlock>
            </Panel>
        </CreateUserContextProvider>
    )
};

export default SignUp;