import React from "react";
import styled, { css } from "styled-components";

const Notification = styled.div`
    display: flex;
    top: -1px;
    height: 44px;
    left: 0;
    right: 0;
    background-color: #E6FFE8;
    border-bottom: 2px solid #5AE05A;
    position: absolute;
    padding: 7px 16px 7px 16px;
    ${props => props.alert && css`
        background-color: ${props.alert === "success" ? "#E6FFE8" : props.alert === "warning" ? "#fff6db" : "#FFF4F4"};
        border-bottom: 2px solid ${props.alert === "success" ? "#5AE05A" : props.alert === "warning" ? "#FBC20F" : "#FF0000"} ;
    `}
`;

export default Notification;