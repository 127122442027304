import MarginContainer, {FlexContainer} from "../../components/layout/margin-container";
import Image from "../../components/image";
import Input, {InputWithIcon} from "../../components/form/input";
import Label from "../../components/text/label";
import StyledLink from "../../components/text/link";
import Button from "../../components/button";
import StyledText from "../../components/text/styled-text";
import React, { Fragment } from "react";

export const LoginPanel = ({username, password, cookie, onLogin, onSubmit, onChangeField}) => {
    return (
        <Fragment>
            <MarginContainer margin={"79px 92px 32px"}>
                <Image src={"/images/riselogoblue.png"}/>
            </MarginContainer>
            <FlexContainer>
                <form method={"post"} action={"/Home/Login"} onSubmit={onSubmit}>
                    <Input name={"__RequestVerificationToken"} type={"hidden"} value={cookie}/>
                    <FlexContainer align={"start"}>
                        <Label>Email</Label>
                        <InputWithIcon type={"email"} id={"username"} name={"Email"} icon={"envelope"} value={username} onChange={onChangeField}/>
                    </FlexContainer>
                    <FlexContainer align={"start"} margin={"10px 0 0 0"}>
                        <Label>Password</Label>
                        <InputWithIcon id={"password"} name={"Password"} icon={"lock"} type={"password"} value={password} onChange={onChangeField}/>
                    </FlexContainer>
                    <FlexContainer direction={"row"} justify={"space-between"} margin={"30px 0 0 0"} width={"300px"}>
                        <StyledLink to={"/recoverpassword"}>Forgot Password?</StyledLink>
                        <Button type={"submit"} onClick={onLogin}>Login</Button>
                    </FlexContainer>
                    <FlexContainer direction={"row"} margin={"30px 0 0 0"}>
                        <StyledText color={"black"} fontSize={"14px"}>
                            Don't have an account? <StyledLink to={"/register"}>Sign up</StyledLink>
                        </StyledText>
                    </FlexContainer>
                </form>
            </FlexContainer>
        </Fragment>
    )
};