import React, { useState, useEffect } from "react";

const useCookie = (cookieName) => {
    const tempName = `${cookieName}=`;
    const [cookie, setCookie ] = useState("");
    
     function getCookieForDom(name){
         
         const decodedCookie = decodeURIComponent(document.cookie);
         const ca = decodedCookie.split(";");
         for(let i = 0; i < ca.length; i++){
             let c = ca[i];
             while(c.charAt(0) === ' '){
                 c =c.substring(1);
             }
             if(c.indexOf(name) === 0){
                 setCookie(c.substring(name.length, c.length));
             }
         }
     }
     
     useEffect(() => {
        getCookieForDom(tempName); 
     }, [tempName]);
     
    return cookie;
};

export default useCookie;