import {useState, useEffect} from "react";

const useMessage = (value, messageText) => {
    const [message, setMessage ] = useState("");
    const setType = (value) => {
        if(value){
            switch(parseInt(value)){
                case -1:
                    setMessage(messageText);
                    break;
                case 0:
                    setMessage("Check your email for a link to reset your password.");
                    break;
                case 1:
                    setMessage("Success! Your email has been confirmed.");
                    break;
                case 2:
                    setMessage("Oh no! Something went wrong.");
                    break;
                case 3:
                    setMessage("Success! Your password has been reset.");
                    break;
                case 4:
                    setMessage("Success! You have been signed out.");
                    break;
                case 5:
                    setMessage("Success!");
                    break;
                case 6:
                    setMessage("You must confirm your email address before signing in.");
                    break;
                case 7:
                    setMessage("A user for that email could not be found. Please try a different e-mail address.");
                    break;
                case 8:
                    setMessage("Error. That email is already being used.");
                    break;
                case 9:
                    setMessage("Success! Your account has been created.");
                    break;
                case 10:
                    setMessage("Invalid username or password.");
                    break;
                case 11:
                    setMessage("Confirmation link has expired.");
                    break;
                default:
                    setMessage("Oh no! Something went wrong.");
                    break;
            }
        }
    };
    useEffect(() => {
        setType(value);
    }, [value, setType]);
    return message;
};

export default useMessage;