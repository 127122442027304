import {FlexContainer} from "../../components/layout/margin-container";
import StyledText from "../../components/text/styled-text";
import Notification from "../../components/notification";
import React from "react";

export const Message = ({message, alert}) => (
    <Notification alert={alert}>
        <FlexContainer justify={"center"} align={"center"} width={"100%"}>
            {alert === "success" ? (
                <StyledText color={"#259F25"} fontSize={"16px"}>
                    {message}
                </StyledText>
            ) : alert === "warning" ?  (
                <StyledText color={"#FBC20F"} fontSize={"16px"}>
                    {message}
                </StyledText>
            ) : (
                <StyledText color={"#FF0000"} fontSize={"16px"}>
                {message}
                </StyledText>
                )}
        </FlexContainer>
    </Notification>
);