import React from "react";
import styled from "styled-components";

const ActionBlock = styled.div`
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export default ActionBlock;