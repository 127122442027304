import React, { Fragment, useContext } from "react";
import styled, { css } from "styled-components";
import StyledText from "./text/styled-text";
import {FlexContainer} from "./layout/margin-container";
import {CreateUserContext} from "../contexts/createUserContext";

const Circle = styled.div`
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: #CBEBFF;
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
`;

const Doughnut = styled.div`
    border-radius: 50%;
    width: 11px;
    height: 11px;
    border: 2px solid #FFFFFF;
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
    background-color: #36A2F9;
`;

const ListWrap = styled.div`
    //width: 235px;
    height: 133px;
    display: inline-flex;
    flex-direction: row;
`;

const Line = styled.div`
    border: 1px solid #FFFFFF;
    height: 130px;
    margin: 0 9px 0 9px;
`;

const ColumnContainer = styled.div`
    flex-direction: column;
    display: inline-flex;
`;

const LineItem = ({index, isSelected, children}) => {
      const margin = `${parseInt(index) === 0 ? "-9.5px": 0} auto 11px -17px`;
      return (
          <FlexContainer direction={"row"} margin={margin}>
              {isSelected ? <Doughnut margin={"0 11px 0 -0.5px"}/> : <Circle margin={"0 15px 0 0"}/>}
              <StyledText font={"Montserrat"} weight={"400"} fontSize={"14px"}>{children}</StyledText>
          </FlexContainer>
      )  
};



const ProgressBar = () => {
    const { currentState } = useContext(CreateUserContext);
    const list = [
        { key: 0, value: "What's your name?"},
        { key: 1, value: "How do you want to log in?"},
        { key: 2, value: "Which account type are you?"},
        { key: 3, value: "Confirmation"}
    ];
    return (
        <ListWrap>
            <Line/>
            <ColumnContainer>
                {list.map( ({key, value}) => (
                    <LineItem key={key} index={key} isSelected={currentState === parseInt(key)}>{value}</LineItem>
                ))}
            </ColumnContainer>
        </ListWrap>
    )
};

export default ProgressBar;