import React from "react";
import styled, { css } from "styled-components";
const UnOrderedList = styled.ul`
    margin: 0;
    padding: 0;
    width: 300px;
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
    ${props => props.columns && css`
        column-count: ${props.columns};
        column-width: 30px;
    `}
    ${props => props.debug && css`
        column-rule: solid 2px red;
    `}
    ${props => props.type && css`
        list-style:none !important;
        li::before {
              background-color: ${props.type.color};
              border-radius: 50%;
              content: "";
              display: inline-block;
              margin-right: 10px;
              height: 6px;
              width: 6px;
        }
        li {
            color: black;
            font-size: 10px;
        }
    `}
`;

export default UnOrderedList;