import React from "react";
import styled from "styled-components";

const Panel = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 10px #00000029;
    border-radius: 8px;
    opacity: 1;
    width: 1110px;
    max-width: 1110px;
    height: 576px;
    display: inline-flex;
    overflow: hidden;
    position: relative;
`;

export default Panel;