import React, { useState, useEffect } from "react";
import InfoBlock from "../components/layout/info-block";
import MarginContainer, {FlexContainer} from "../components/layout/margin-container";
import HeadingH1, {HeadingH4} from "../components/text/heading";
import ColoredLine from "../components/colored-line";
import StyledText from "../components/text/styled-text";
import List, {ListItem} from "../components/layout/list";
import ActionBlock from "../components/layout/action-block";
import Image from "../components/image";
import Label from "../components/text/label";
import Input, {InputWithIcon} from "../components/form/input";
import StyledLink from "../components/text/link";
import Button from "../components/button";
import Panel from "../components/layout/panel";
import useCookie from "../hooks/cookiehook";
import Notification from "../components/notification";
import useMessage from "../hooks/messagehook";
import {DefaultContent} from "./shared/content";
import {Message} from "./shared/message";
import { LoginPanel } from "./shared/login-panel";
import useDetectBrowser from "./../hooks/detect-browser";

const Login = ({alert, type, messageText}) => {
    const message = useMessage(type, messageText);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const browser = useDetectBrowser();
    const cookie = useCookie("X-XSRF-TOKEN");
    
    const onLogin = e => {
        console.log("login clicked");
    };

    useEffect(() => {
        if (browser == "ie")
            window.alert("Not all features will work in this browser.");
    }, []);
    
    const onSubmit = e => {
        e.preventDefault();
        console.log(e);
        e.target.submit();
    };
    
    const onChangeField = e => {
        console.log(`Target ID: ${e.currentTarget.id} Value: ${e.currentTarget.value}`);
        if(e.currentTarget.id === "username")
            setUsername(e.currentTarget.value);
        else
            setPassword(e.currentTarget.value);
    };
    
    return (
        <Panel>
            <InfoBlock>
                <DefaultContent/>
            </InfoBlock>
            <ActionBlock>
                {message &&
                    <Message message={message} alert={alert}/>
                }   
                <LoginPanel username={username} password={password} onSubmit={onSubmit} cookie={cookie} onChangeField={onChangeField} onLogin={onLogin}/>
            </ActionBlock>
        </Panel>
    );
};

export default Login;