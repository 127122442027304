import React from "react";
import styled, { css } from "styled-components";

const StyledText = styled.div`
    font-family: "Open Sans";
    font-size: 18px;
    color: white;
    line-height: 30px;
    ${props => props.color && css`
        color: ${props.color};
    `}
    ${props => props.fontSize && css`
        font-size: ${props.fontSize};
    `}
    ${props => props.font && css`
        font-family: ${props.font};
    `}
    
    ${props => props.weight && css`
        font-weight: ${props.weight};
    `}
    
    ${props => props.lineHeight && css`
        line-height: ${props.lineHeight};
    `}
    
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
`;

export default StyledText;