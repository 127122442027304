export const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '12px',
            fontWeight: 400,
            '::placeholder': {
                color: '#ADADAD',
                fontWeight: 400,
                fontSize: "12px",
                opacity: 0.5
            },
            width: "300px"
        },
        invalid: {
            color: '#FF0000',
            iconColor: '#FF0000',
            backgroundColor: '#FFF4F4'
        }
    }
};