import React from "react";
import styled from "styled-components";

const HeadingH1 = styled.h1`
    font-family: 'Montserrat' !important;
    font-size: 38px;
    font-weight: 700;
    color: white;
    margin: 0;
    line-height: 42px;
`;

export const HeadingH4 = styled.h4`
    font-family: 'Montserrat' !important;
    font-size: 28px;
    font-weight: 700;
    color: white;
    margin: 0;
    line-height: 42px;
`;

export default HeadingH1;