import React from "react";
import styled, { css } from "styled-components";
import '../sass/main.scss';
import {Link} from "react-router-dom";

const Button = styled.button`
    background: #36A2F9 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    padding: 5px 27px 7px 27px;
    font-size: 16px;
    font-family: 'Open Sans';
    border: 2px solid #36A2F9;
    line-height: 22px;
    color: white;
    height: 36px;
    cursor: pointer;
    transition: background-color .5s;
    &:hover{
        background-color: #228ee5;
    }
    ${props => props.bordered && css`
        background: white 0% 0% no-repeat padding-box;
        border: 2px solid #36A2F9;
        &:hover{
            background-color: #EBF6FF;
        }
        color: #36A2F9;
    `}
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
    
    ${props => props.disabled && css`
        opacity: 50%;
        cursor: not-allowed;
    `}
`;

export const ButtonLink = styled(Link)`
    background: #36A2F9 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    padding: 5px 27px 5px 27px;
    font-size: 16px;
    font-family: 'Open Sans';
    border: none;
    line-height: 22px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    transition: background-color .5s;
    &:hover{
        background-color: #228ee5;
    }
    ${props => props.bordered && css`
        background: white 0% 0% no-repeat padding-box;
        border: 2px solid #36A2F9;
        &:hover{
            background-color: #EBF6FF;
        }
        color: #36A2F9;
    `}
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
`;

export default Button;