import React from "react";
import styled, { css } from "styled-components";

const List = styled.ul`
    margin: 0 0 0 0;
    ${props => props.style && css`
        list-style: ${props.style};
    `}
    color: white;
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
`;

export const ListItem = styled.li`
    color: white;
    font-family: "Open Sans";
    font-size: 18px;
    line-height: 30px;
    ${props => props.lineHeight && css`
        line-height: ${props.lineHeight};
    `}
    ${props => props.textAlign && css`
        text-align: ${props.textAlign};
    `}
    ${props => props.margin && css`
        margin: ${props.margin};
    `}
`;

export default List;