import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
    font-family: "Open Sans";
    font-size: 14px;
    color: #36A2F9;
    text-decoration: underline;
    cursor: pointer;
    line-height: 22px;
    transition: color .5s
    &:hover : {
        color: #228ee5;
    }
`;

export default StyledLink;