import React, {Fragment, useContext, useEffect, useState} from "react";
import styled, {css} from "styled-components";
import StyledText from "../text/styled-text";
import {InputContext} from "../../contexts/inputcontextprovider";

const InputBase = styled.input`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BFD7E6;
    border-radius: 2px;
    opacity: 1;
    width: 276px;
    padding-right: 12px;
    padding-left: 12px;
    text-align: left;
    font-family: Open Sans;
    letter-spacing: 0;
    color: #555555;
    opacity: 1;
    font-size: 12px;
    line-height: 32px;
    max-height: 36px;
    min-width: 0;
    flex-basis: 100%;
    max-width: 274px;
    ${props => props.paddingLeft && css`
        padding-left: ${props.paddingLeft}
    `};
    ${props => props.hasErrors && css`
        border-color: #FF0000;
        background-color: #FFF4F4;
    `}
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    max-height: 36px;
    max-width: 300px;
`;

const Icon = styled.i.attrs(({icon}) => ({
    className: `fas fa-${icon}`
}))`
    position: absolute;
    left: 12px;
    top: 11px;
    bottom: 11px;
    color: #FBC20F;
    font-size: 14px;
    line-height: 16px;
`;

export const InputWithIcon = ({icon, ...rest}) => {
    let paddingTextLeft = icon ? '36px' : '24px';
    return (
        <Wrapper>
            <Icon icon={icon}/>
            <Input paddingLeft={paddingTextLeft} {...rest}/>
        </Wrapper>
    )
};

const Input = ({onChange, required, validation, name, ...rest}) => {
    const [hasErrors, setHasErrors] = useState(false);
    const {errors, register, update} = useContext(InputContext);
    
    useEffect(() => {
       init()
    }, []);
    
    const init = () => {
        let { value } = {...rest};
        register(name);
        validate(name, value, validation);
    };
    
    const updateState = e => {
        const {name, value} = e.currentTarget;
        const isInvalid = validate(name, value, validation);
        setHasErrors(isInvalid);
        onChange(e);
    };
    
    const validate = (field, value, validation) => {
        let isInvalid;
        if(!value || !validateNotEmpty(value))
            isInvalid = true;
        else isInvalid = !validateWithValidation(value, validation);
        update(field, !isInvalid);
        return isInvalid;
    };
    
    const validateNotEmpty = value => {
        const notEmptyRegEx = /[^\s]/gi;
        return notEmptyRegEx.test(value);
    };
    
    const validateWithValidation = (value, validation) => {
        if(!validation) return true;
        return validation.test(value);
    };
    
    return (
        <Fragment>
            <InputBase onChange={required ? updateState : onChange} name={name} {...rest} hasErrors={hasErrors}/>
            {hasErrors &&
                <StyledText color={"#FF0000"} fontSize={"10px"}>
                    {validation && "Valid "}{name.replace(/([A-Z])/g, ' $1').trim()} is required.
                </StyledText>
            }
        </Fragment>
    )
};

export default Input;