import React from "react";

export const IS_VALID = "isValid";
export const IS_NOT_VALID = "isNotValid";

const InputReducer = (state, action) => {
    let s = {...state};
    let { field, type } = { ... action };
    switch(type){
        case IS_VALID:
            s[field] = true;
            break;
        case IS_NOT_VALID:
            s[field] = false;
            break;
    }
    return s;
};

export default InputReducer;