import React, {useState, useEffect} from "react";
import Panel from "../components/layout/panel";
import InfoBlock from "../components/layout/info-block";
import MarginContainer, {FlexContainer} from "../components/layout/margin-container";
import HeadingH1, {HeadingH4} from "../components/text/heading";
import ColoredLine from "../components/colored-line";
import StyledText from "../components/text/styled-text";
import UnOrderedList from "../components/layout/unorderedlist";
import ActionBlock from "../components/layout/action-block";
import Image from "../components/image";
import Input, {InputWithIcon} from "../components/form/input";
import Label from "../components/text/label";
import StyledLink from "../components/text/link";
import Button, {ButtonLink} from "../components/button";
import useCookie from "../hooks/cookiehook";
import List, {ListItem} from "../components/layout/list";
import Notification from "../components/notification";
import {DefaultContent} from "./shared/content";

const ResetPassword = ({userId, code}) => {
    const cookie = useCookie("X-XSRF-TOKEN");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [alert, setAlert] = useState("");
    const listLineHeight = "20px";
    const passwordValidation = /^(?=[\x21-\x7E]*[0-9])(?=[\x21-\x7E]*[A-Z])(?=[\x21-\x7E]*[a-z])(?=[\x21-\x7E]*[\x21-\x2F|\x3A-\x40|\x5B-\x60|\x7B-\x7E])[\x21-\x7E]{8,}/g;
    const onChangeField = e => {
        console.log(`Target ID: ${e.currentTarget.id} Value: ${e.currentTarget.value}`);
        if(e.currentTarget.id === "password")
            setPassword(e.currentTarget.value);
        else
            setConfirmPassword(e.currentTarget.value);
    };
    
    const getEmailWithId = async userId => {
        if(!cookie) return;
        try{
            const result = await fetch(`/Account/${userId}/email`, {
                method: "GET",
                headers : {
                    "RequestVerificationToken" : cookie
                }
            });
            if(result.ok){
                const emailField = document.getElementById("email");
                const response = await result.json();
                emailField.value = response.email;
            }else{
                console.log(`Getting Error when fetching email address: ${result.statusText}`);
            }
        }catch(e){
            console.log(`Getting Error when fetching email address: ${e.descriptiont}`);
        }
    };
    
    const onSubmit = e => {
      e.preventDefault();
      let passWorks = passwordValidation.test(password);
      if(passWorks) {
          if (password === confirmPassword && (password !== "" && confirmPassword !== "")) {
              e.currentTarget.submit();
          }else{
              setMessage("Passwords do not match.");
              setAlert("alert");
              setPassword("");
              setConfirmPassword("")
          }
      }else{
          setMessage("Invalid Password.");
          setAlert("alert")
      }
    };
    
    useEffect(() => {
        (async() => getEmailWithId(userId))();
    }, [userId, cookie]);
    return (
        <Panel>
            <InfoBlock>
                <DefaultContent/>
            </InfoBlock>
            <ActionBlock>
                {message &&
                    <Notification alert={alert}>
                        <FlexContainer justify={"center"} align={"center"} width={"100%"}>
                            {alert === "success" ? (
                                <StyledText color={"#259F25"} fontSize={"16px"}>
                                    {message}
                                </StyledText>
                            ) : (
                                <StyledText color={"#FF0000"} fontSize={"16px"}>
                                    {message}
                                </StyledText>
                            )}
                        </FlexContainer>
                    </Notification>
                }
                <FlexContainer margin={"73px 92px 32px"} padding={"0 84px"}>
                    <Image src={"/images/padlock.png"}/>
                </FlexContainer>
                <FlexContainer>
                    <FlexContainer align={"start"} width={"300px"} margin={"0 0 21px 0"}>
                        <StyledText color={"#555555"} font={"Montserrat"} weight={"700"}>Reset your Password?</StyledText>
                        <ColoredLine width={"38px"} thickness={"1.5px"}/>
                    </FlexContainer>
                    <form method={"post"} action={"/Account/ResetPassword"} onSubmit={onSubmit}>
                        <Input name={"__RequestVerificationToken"} id={"validationToken"} type={"hidden"} value={cookie}/>
                        <Input name={"Email"} id={"email"} type={"hidden"}/>
                        <Input name={"Code"} type={"hidden"} value={code}/>
                        <FlexContainer align={"start"}>
                            <Label>Password</Label>
                            <InputWithIcon id={"password"} name={"Password"} icon={"lock"} type={"password"} value={password} onChange={onChangeField}/>
                        </FlexContainer>
                        <FlexContainer>
                            <UnOrderedList columns={2} type={{color: "#36A2F9"}} margin={"16px 0 16px 0"}>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight}>One lowercase character</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight}>One uppercase character</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight}>One number</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight} margin={"0 0 0 16px"}>One special character</ListItem>
                                <ListItem color={"#555555"} fontSize={"10px"} lineHeight={listLineHeight} margin={"0 0 0 16px"}>8 characters minumum</ListItem>
                            </UnOrderedList>
                        </FlexContainer>
                        <FlexContainer align={"start"} >
                            <Label>Confirm Password</Label>
                            <InputWithIcon id={"confirmpassword"} name={"ConfirmPassword"} icon={"lock"} type={"password"} value={confirmPassword} onChange={onChangeField}/>
                        </FlexContainer>
                        <FlexContainer direction={"row"} justify={"flex-end"} margin={"32px 0 0 0"} width={"300px"}>
                            <Button type={"submit"}>Reset</Button>
                        </FlexContainer>
                    </form>
                </FlexContainer>
            </ActionBlock>
        </Panel>
    );
};

export default ResetPassword;