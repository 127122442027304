import React from "react";
import Panel from "../components/layout/panel";
import InfoBlock from "../components/layout/info-block";
import MarginContainer, {FlexContainer} from "../components/layout/margin-container";
import HeadingH1, {HeadingH4} from "../components/text/heading";
import ColoredLine from "../components/colored-line";
import StyledText from "../components/text/styled-text";
import List, {ListItem} from "../components/layout/list";
import ActionBlock from "../components/layout/action-block";
import Image from "../components/image";
import Input, {InputWithIcon} from "../components/form/input";
import Label from "../components/text/label";
import StyledLink from "../components/text/link";
import Button, {ButtonLink} from "../components/button";
import useCookie from "../hooks/cookiehook";
import {DefaultContent} from "./shared/content";

const ForgotPassword = () => {

    const cookie = useCookie("XSRF-TOKEN");
    
    const goBack = e => {
        window.location.href = "/";
    };
    
    return (
        <Panel>
            <InfoBlock>
                <DefaultContent/>
            </InfoBlock>
            <ActionBlock>
                <FlexContainer margin={"73px 92px 32px"} padding={"0 84px"}>
                    <Image src={"/images/commentbubble.png"}/>
                </FlexContainer>
                <FlexContainer>
                    <FlexContainer align={"start"} width={"300px"} margin={"0 0 24px 0"}>
                        <StyledText color={"#555555"} font={"Montserrat"} weight={"700"}>Forgot your Password?</StyledText>
                        <ColoredLine margin={"5px 0 20px 2px"} width={"38px"} thickness={"1.5px"}/>
                        <StyledText color={"#555555"} fontSize={"12px"} lineHeight={"16px"}>
                            Please enter the email address you used when creating your account. We’ll send you instructions to reset your password.
                        </StyledText>
                    </FlexContainer>
                    <form method={"post"} action={"/Account/ForgotPassword"}>
                        <Input name={"__RequestVerificationToken"} type={"hidden"} value={cookie}/>
                        <FlexContainer align={"start"} margin={"0 0 32px 0"}>
                            <Label>Email</Label>
                            <InputWithIcon type={"email"} id={"username"} name={"Email"} icon={"envelope"}/>
                        </FlexContainer>
                        <FlexContainer direction={"row"} justify={"flex-end"} margin={"30px 0 0 0"} width={"300px"}>
                            <ButtonLink type={"button"} bordered margin={"0 12px 0 0"} to={"/"}>Back</ButtonLink>
                            <Button type={"submit"}>Send</Button>
                        </FlexContainer>
                    </form>
                </FlexContainer>
            </ActionBlock>
        </Panel>
    );
};

export default ForgotPassword;